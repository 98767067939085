* {
    box-sizing: border-box;
}

.info-button {
    width: 60px;
    height: 60px;
    margin-top: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.info-icon {
    width: 100%;
    height: 100%;
    padding: 0;
    background: transparent;
    border: none;
    transition: all 0.3s ease;
}
