.song-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.music-title {
    margin: 4px 0;
    font-size: 2.2rem;
    text-align: center;
}

.music-artist {
    color: var(--mainDimColor);
    margin: 4px 0;
    font-size: 1.4rem;
    font-weight: 400;
    padding-bottom: 10px;
}

.song-avatar-wrapper {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-top: 8px 0;
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
}

.song-avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}