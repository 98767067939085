* {
    box-sizing: border-box;
}

.library-button {
    width: 100%;
    height: 100px;
    margin: 0;
    margin-top: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.library-toggle {
    font-size: 18px;
    background: transparent;
    cursor: pointer;
    border: 2px solid rgb(65, 65, 65);
    padding: 10px;
    transition: all 0.3s ease;
}

.library-toggle:hover {
    background: rgba(123, 123, 186, 0.419);
    color: white;
}