
:root{
    --mainColor: #fff;
    --mainDimColor: rgb(228,228,228);
}

* {
    box-sizing: border-box;
}

.container{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mainColor);
    position: relative; 
    overflow: hidden;
}

.background-image {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    height: 100vh;
    z-index: -1;
    filter: saturate(2.5);
}

.library-container{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 350px;
    z-index: 90;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background: linear-gradient(to bottom, rgba(175, 170, 255, 0.95), rgba(255, 170, 175, 0.95)),
}

.info-container{
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 350px;
    z-index: 89;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background-color: rgba(70, 60, 56, 0.901);
}


.music-container{
    width: 350px;
    padding: 35px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 36px;
    box-shadow: 0 0 20px rgba(26,26,26,0.1),
    0 0 40px rgba(26,26,26,0.1),
    0 0 80px rgba(26,26,26,0.1);
    transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    backdrop-filter: blur(15px);
    font-weight: 600;
}

.notification-container {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 300px;
    z-index: 99;
    border-radius: 25px;
    padding-right: 15px;
    padding-bottom: 15px;
}

