.notification {
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(30, 30, 30);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(26,26,26,0.1);
}


.notification-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Ensure the close button is positioned relative to this container */
}

p {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 10px;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 25px;
    color: #333;
}
