.info-page {
    width: 350px;
    margin: 0 auto; 
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}

.xpz-music-house-title {
    font-size: 2em;
    color: #ffffff;
    text-align: center; /* Center the text within the div */
    margin: 20px; 
    padding: 0;
}

.welcome {
    font-size: 20px;
    width: 80%;
    text-align: left;
    margin: 10px;
}

.info-body {
    font-size: 18px;
    width: 80%;
    text-align: left;
    margin: 0;
    overflow-y: auto;
}

.info-page a {
    color: #9e9c9f;
}

.icon-wrapper {
    height: 20px;
    margin: 2px;
    color: #9e9c9f;
}

.icon-with-text {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}


.text-wrapper {
    display: flex;
    flex-direction: column;
}

.text-wrapper i {
    display: inline; /* Ensure ": Instrumental" stays inline */
    font-size: 18px;
}

.text-wrapper p {
    margin: 0; /* Remove default margin */
    font-size: 16px;
}

.note {
    font-size: 14px;
    margin: 3px;
}
