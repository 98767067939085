
* {
    box-sizing: border-box;
    --icon-color: #63625f;
}

.library-song {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.song-description {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.library-song .song-description img {
    border-radius: 30%;
    height: 66%;
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
}

.library-song:hover {
    background: rgba(0, 0, 0, 0.1);
}

.song-type-icon-container {
    width: 25px;
    height: 25px;
    color: var(--icon-color);
}

.checkbox-container {
    width: 38px;
    height: 38px;
    padding: 5px;
    display: inline-block;
    z-index: 98;
}

.checkbox {
    width: 28px;
    height: 28px;
    cursor: pointer;
}


